<template>
  <b-card title="Editar Motivo">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="motivo.codigo"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalMotivo"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Descrição"
              label-for="descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="descricao"
                  v-model="motivo.descricao"
                  type="text"
                  placeholder="Descrição"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Gravar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalMotivo"
      hide-footer
      size="xl"
    >
      <selecionarMotivo @motivoSelecionado="selectMotivo" />
    </b-modal>

  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BInputGroupPrepend,
  BInputGroup, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarMotivo from '../../Listagens/Motivos/SelecionarMotivo.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    selecionarMotivo,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      motivo: {
        codigo: '',
        descricao: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneMotivo()
    }
  },

  methods: {
    getOneMotivo() {
      const motivoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/motivos/${motivoId}`

      axios.get(apiUrl)
        .then(res => { this.selectMotivo(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveMotivo()
        }
      })
    },

    saveMotivo() {
      axios.put(`/api/v1/motivos/${this.motivo.id}`, this.motivo)
        .then(this.showToast('success', 'CheckIcon'))
    },

    selectMotivo(row) {
      this.motivo.id = row.id
      this.motivo.codigo = row.codigo
      this.motivo.descricao = row.descricao
      this.hideModalMotivo()
    },

    showModalMotivo() {
      this.$refs.modalMotivo.show()
    },
    hideModalMotivo() {
      this.$refs.modalMotivo.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },

    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
