<template>
  <div>
    <div>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Pesquisar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Pesquisar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'codigo'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.codigo }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'descricao'">

            {{ props.row.descricao }}

          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="selectMotivo(props.row)"
                >
                  Selecionar
                </b-button>
              </div>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrar 1 até
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap "> de {{ props.total }} registos </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div></template>

<script>
import {

  BFormGroup,
  BFormInput, BPagination, BFormSelect,

  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'

import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    VueGoodTable,

    BButton,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      boxTwo: false,
      pageLength: 3,
      searchTerm: '',
      columns: [
        {
          label: 'Código',
          field: 'codigo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Descrição',
          field: 'descricao',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getMotivo()
  },
  methods: {
    getMotivo() {
      axios.get('/api/v1/motivos')
        .then(res => { this.rows = res.data })
      this.$forceUpdate()
    },
    selectMotivo(row) {
      this.$emit('motivoSelecionado', row)
    },

  },
}
</script>
      <style lang="scss">
      @import '@core/scss/vue/libs/vue-good-table.scss';
      </style>
